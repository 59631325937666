import axios from "axios";
import Cookies from "js-cookie";

// For Vue plugin
const install = function(object) {
  object.config.globalProperties.$clients = new Clients();
}

class Clients {
  constructor() {
    this.media = null;
    this.rooms = null;
    this.auth = null;
    this.form = null;
    this.initialized = false;
  }

  initializeClients() {
    if (this.initialized) {
      return;
    }

    const mediaUrl = document.getElementById("media-url").value;
    this.media = axios.create({
      baseURL: `${mediaUrl}api/v4`,
      headers: {
        Authorization: getToken('media'),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    refreshStrategy(this.media)

    const roomsUrl = document.getElementById("rooms-url").value;
    this.rooms = axios.create({
      baseURL: roomsUrl,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    refreshStrategy(this.rooms)

    const authServiceUrl = document.getElementById("auth-service-url").value;
    this.auth = axios.create({
      baseURL: `${authServiceUrl}/api/v1`,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
      },
    });
    refreshStrategy(this.auth)

    const authServiceHostUrl = document.getElementById("auth-service-request-url").value;
    this.form = axios.create({
      baseURL: authServiceHostUrl,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    this.oauth = axios.create({
      baseURL: `${authServiceUrl}`,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
      },
    });
    refreshStrategy(this.oauth)

    this.initialized = true;
    return this
  }

    initialize() {
      return this.initializeClients()
    }
}

// For non-Vue usage
const apiClient = new Clients().initialize()

function getToken(app) {
  let token = Cookies.get('auth._token.apptegy') || '';
  if (app === 'media') {
    token = token.replace('Bearer+', 'Openid ');
  } else {
    token = token.replace('+', ' ');
  }
  return token;
}

async function refreshToken(axios_client, error) {
  try {
    const authServiceUrl = document.getElementById("auth-service-url").value;

    var options = {
      method: 'POST',
      url: `${authServiceUrl}/oauth/token`,
      headers: {
        'content-type': 'application/json',
        Accept: "application/json",
      },
      data: {
        grant_type: 'refresh_token',
        client_id: Cookies.get('auth._refresh_client_id.apptegy'),
        refresh_token: Cookies.get('auth._refresh_token.apptegy'),
      }
    };

    const response = await axios_client.request(options)
    const isMedia = axios_client.defaults.headers.Authorization.includes('Openid')

    if (isMedia) {
      axios_client.defaults.headers.Authorization = `Openid ${response.data.id_token}`
      error.config.headers.Authorization = `Openid ${response.data.id_token}`
    } else {
      axios_client.defaults.headers.Authorization = `Bearer ${response.data.id_token}`
      error.config.headers.Authorization = `Bearer ${response.data.id_token}`
    }

    Cookies.set('auth._token.apptegy', response.data.id_token)
    Cookies.set('auth._refresh_token.apptegy', response.data.refresh_token)

    return await axios_client.request(error.config)
  } catch(err) {
    throw err;
  }
}

function refreshStrategy(axios_client) {
  axios_client.interceptors.response.use(
    response => response, 
    async error => {
      if (error.response.status === 401) {
        return await refreshToken(axios_client, error);
      } else {
        throw error;
      }
  });
}

export { install as default, apiClient }
